<template>
  <!-- 开方式基金-个人交易-资金流水 -->
    <div style="margin-left:20px;float:right;height: 40px;"> 
      <!-- 查询历史 &nbsp -->
      <el-tooltip effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
          <i style="color: #3B6EFB" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
      <el-date-picker size="mini" v-model="data.historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期" @change="listCf()"  end-placeholder="结束日期" value-format="YYYY-MM-DD">
      </el-date-picker>
    </div>
    <div style="margin-top: 20px;">
      <el-table :data="data.tableData" style="width: 100%"  :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
      <el-table-column prop="tradeTime" label="时间"  />
      <el-table-column prop="flowType" label="交易类型">
		  <!-- 16 管理费  17 托管费  18 申购费  19 赎回费  20 基金交易 -->
		   <template #default="scope">
 			   <span v-show="scope.row.flowType == '16'">管理费</span>
			   <span v-show="scope.row.flowType == '17'">托管费</span>
			   <span v-show="scope.row.flowType == '18'">申购费</span>
			   <span v-show="scope.row.flowType == '19'">赎回费</span> 
			   <span v-show="scope.row.flowType == '20'">基金交易</span> 
		   </template>
	  </el-table-column>
      <el-table-column prop="amounts" label="现金周转" />
    </el-table>
    </div>
</template>

<script setup>
import { reactive, toRefs ,onMounted} from 'vue';
import {listCapitalFlow} from '../../api.js';

	let data =  reactive({
	  historicalTime:[],
	  tableData:[]
	})
	const listCf = () =>{
			let parm={
				"acid":sessionStorage.getItem("acid"),
				"startTime":data.historicalTime[0],
				"endTime":data.historicalTime[1],
				"page":1,
				"pageSize":10
			};
			listCapitalFlow(parm).then(res => {
				data.tableData = res.data.data.list;
			})
	}
	onMounted(() => {
		listCf();
	})
</script>