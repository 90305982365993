import { v4 as uuidv4 } from "uuid";

//个人交易左侧股票tab
export const stockList = [
    {
        name: '行情展示',
        type:'stock',
        key: '1',
        componentName:'market'
    },
    {
        name: '买入',
        type:'stock',
        key: '2',
        componentName:'buy'
    },
    {
        name:'卖出',
        type:'stock',
        key: '3',
        componentName:'sale'
    },
    {
        name: '撤单',
        type:'stock',
        key: '4',
        componentName:'Withwithdrawal'
    },
    {
        name:'我的成交',
        type:'stock',
        key: '5-2',
        componentType:'TransactionDay',
        componentName:'OperationDay'
    },
    {
        name:'我的委托',
        type:'stock',
        key: '5-3',
        componentType:'DelegsignmentDay',
        componentName:'OperationDay'
    },
    // {
    //     name:'历史成交',
    //     type:'stock',
    //     key: '5-4',
    //     componentType:'History',
    //     componentName:'HistoryTransaction'
    // },
    {
        name:'资金流水',
        type:'stock',
        key: '5-4',
        // componentName:'journalcapital'
        componentType:'HistoryTransaction',
        componentName:'OperationDay',
    },
    {
        name:'资金股份',
        type:'stock',
        key: '5-5',
        componentType:'capitalSharesTion',
        componentName:'OperationDay'
    },
    // {
    //     name: '查询',
    //     type:'stock',
    //     key: '5',
    //     componentName:'',
    //     children:[
    //         // {
    //         //     name:'资金股份',
    //         //     type:'stock',
    //         //     key: '5-1',
    //         //     componentName:'Founds'
    //         // },
    //
    //     ]
    // },

]
//个人交易左侧期货tab
export const futuresList = [
    {
        name:'行情展示',
        type:'futures',
        key: '1',
        componentName:'market'
    },
    // {
    //     name:'开仓/平仓',
    //     type:'futures',
    //     key: '2',
    //     componentName:'futuresstorage'
    // },
    // {
    //     name:'撤单',
    //     type:'futures',
    //     key: '3',
    //     componentName:'Withwithdrawal'
    // },
    // {
    //     name:'资金股份',
    //     type:'futures',
    //     key: '4-1',
    //     componentName:'Founds'
    // },
    // {
    //     name:'我的成交',
    //     type:'futures',
    //     key: '4-2',
    //     componentType:'TransactionDay',
    //     componentName:'OperationDay'
    // },
    // {
    //     name:'我的委托',
    //     type:'futures',
    //     key: '4-3',
    //     componentType:'DelegsignmentDay',
    //     componentName:'OperationDay'
    // },
    // {
    //     name:'成交历史',
    //     type:'futures',
    //     key: '4-4',
    //     componentName:'HistoryTransaction',
    //     componentType:'History',
    // },
    // {
    //     name:'资金流水',
    //     type:'futures',
    //     key: '4-4',
    //     componentType:'HistoryTransaction',
    //     componentName:'OperationDay',
    //     // componentName:'fcapitalflow'
    // },
    // {
    //     name:'资金股份',
    //     type:'stock',
    //     key: '5-5',
    //     componentType:'capitalSharesTion',
    //     componentName:'OperationDay'
    // },
    // {
    //     name:'结算单',
    //     type:'futures',
    //     key: '4-6',
    //     componentName:'fsettlementform'
    // },
    // {
    //     name:'查询',
    //     type:'futures',
    //     key: '4',
    //     componentName:'',
    //     children:[
    //
    //     ]
    //
    // },

]
//个人交易左侧债券tab
export const bondsList = [
    {
        name:'行情展示',
        type:'bonds',
        key: '1',
        componentName:'market'
    },
    {
        name:'买入',
        type:'bonds',
        key: '2',
        componentName:'bbuy'
    },
    {
        name:'卖出',
        type:'bonds',
        key: '3',
        componentName:'bsale'
    },
    {
        name:'撤单',
        type:'bonds',
        key: '4',
        componentName:'Withwithdrawal'
    },
    // {
    //     name:'资金持仓',
    //     type:'bonds',
    //     key: '5-1',
    //     componentName:'Founds',
    // },
    {
        name:'我的成交',
        type:'bonds',
        key: '5-2',
        componentType:'TransactionDay',
        componentName:'OperationDay'
    },
    {
        name:'我的委托',
        type:'bonds',
        key: '5-3',
        componentType:'DelegsignmentDay',
        componentName:'OperationDay'
    },
    {
        name:'资金流水',
        type:'bonds',
        key: '5-4',
        componentType:'HistoryTransaction',
        componentName:'OperationDay',
        // componentName:'fcapitalflow'
    },
    {
        name:'资金股份',
        type:'stock',
        key: '5-5',
        componentType:'capitalSharesTion',
        componentName:'OperationDay'
    },
    // {
    //     name:'成交历史',
    //     type:'bonds',
    //     key: '5-4',
    //     componentName:'HistoryTransaction',
    // },
    // {
    //     name:'查询',
    //     type:'bonds',
    //     key: '5',
    //     componentName:'',
    //     children:[
    //
    //     ]
    // },
]
// 个人交易左侧外汇table
export const ForeignExchangeList = [
    {
        name:'行情展示',
        type:'ForeignExchange',
        key: '1',
        componentName:'ForeignExchangeMarket'
    },
    {
        name:'交易',
        type:'ForeignExchange',
        key: '2',
        componentName:'ForeignExchangeTransactions'
    },
    {
        name:'撤单',
        type:'ForeignExchange',
        key: '3',
        componentName:'ForeignExchangeCancellation'
    },
    {
        name:'资金流水',
        type:'ForeignExchange',
        key: '4',
        componentName:'ForeignExchangeCapitalFlow'
    },
    {
        name:'账户信息',
        type:'ForeignExchange',
        key: '5',
        componentName:'ForeignExchangeAccountInformation'
    },
    {
        name:'成交详情',
        type:'ForeignExchange',
        key: '6',
        componentName:'ForeignExchangeTransactionDetails'
    },
    
]
// 个人交易左侧开放式基金table
export const OpenEndedFundsList = [
    {
        name:'申购',
        type:'OpenEndedFunds',
        key: '1',
        componentName:'FundApplyForPurchasing'
    },
    {
        name:'赎回',
        type:'OpenEndedFunds',
        key: '2',
        componentName:'FundRedeem'
    },
    {
        name:'资金流水',
        type:'OpenEndedFunds',
        key: '3',
        componentName:'FundCapitalFlow'
    },
    {
        name:'资金股份',
        type:'OpenEndedFunds',
        key: '4',
        componentName:'FundCapitalShares'
    },
    {
        name:'成交详情',
        type:'OpenEndedFunds',
        key: '5',
        componentName:'FundTransactionDetails'
    },
    {
        name:'投资者问答',
        type:'OpenEndedFunds',
        key: '6',
        componentName:'FundInvestor'
    },
]


export const LeftTabData = {
    stockList,
    futuresList,
    bondsList,
    ForeignExchangeList,
    OpenEndedFundsList
}


export const initdata = {
    componentName:'market',//当前展示的vue组件
    componentName1:'market',//当前展示的vue组件
    activeTableftkey:'', //当前左侧被选中的key
    activeTableTopkey:'',//当前顶部tab栏被选中的key
    tradeList :stockList,// 动态切换的左侧tab数组
    componentType:'1',  //用来分辨动态组件复用组件左侧菜单选中的是什么组件
    navList:[
        {
            name:'股票',
            type:'stock',
            key: '1',
            siwtchKey:'stockList',
            pageName:'stock',
            componentName:'market'
        },
        {
            name:'期货',
            type:'futures',
            key: '3',
            siwtchKey:'futuresList',
            pageName:'futures',
            componentName:'market'

        },
        {
            name:'债券',
            type:'bonds',
            key: '2',
            siwtchKey:'bondsList',
            pageName:'bonds',
            componentName:'market'
        },
        {
            name:'外汇',
            type:'tab',
            key: '4',
            siwtchKey:'ForeignExchangeList',
            pageName:'fund',
            componentName:'market'
        },
        {
            name:'开放式基金',
            type:'open',
            key: '5',
            siwtchKey:'OpenFuntList',
            pageName:'fund',
            componentName:'market'
        },
    ],

}
