import {
  findCurrencyType,
  add,
  findUserAccountAmount,
} from "./ForeignExchangeTransactionsApi";
import {
  ElMessage
} from "element-plus";
import {
  thousandthConversion
} from '../commonMethods'
import {
  transactionCurrency
} from '@/views/course-manage/table/tableMethods.js'


export const transactionCurrencyss = (data) => {
  transactionCurrency(data, 'abbrPart')
}
//查找所有货币类型
export const getTransactionCurrency = (data) => {
  findCurrencyType().then((res) => {
    if (res.data.code == 200) {
      if (!data.showAbbr) {
        data.options = JSON.parse(JSON.stringify(res.data.data.currencyData))
        data.options2 = JSON.parse(JSON.stringify(res.data.data.currencyData))
      }
      data.optronAllMoney = JSON.parse(JSON.stringify(res.data.data.currencyData))

    }
  })
}

// 添加外汇订单
export const addForeignExchangeOrders = (data, ruleFormRef) => {

  ruleFormRef.value.validate((valid) => {
    if (valid) {
      let radio1 = ''
      if (data.ruleForm.radio1 == '人民币') {
        radio1 = 'CNY'
      } else if (data.ruleForm.radio1 == '美元') {
        radio1 = 'USD'
      } else if (data.ruleForm.radio1 == '港元') {
        radio1 = 'HKD'
      } else if (data.ruleForm.radio1 == '欧元') {
        radio1 = 'EUR'
      } else if (data.ruleForm.radio1 == '英镑') {
        radio1 = 'GBP'
      } else if (data.ruleForm.radio1 == '日元') {
        radio1 = 'JPY'
      } else if (data.ruleForm.radio1 == '澳大利亚元') {
        radio1 = 'AUD'
      } else if (data.ruleForm.radio1 == '加拿大元') {
        radio1 = 'CAD'
      } else if (data.ruleForm.radio1 == '瑞士法郎') {
        radio1 = 'CHF'
      } else if (data.ruleForm.radio1 == '财大点卷') {
        radio1 = 'SUFEY'
      }

      let param = {
        // currencyList: [data.ruleForm.region, data.ruleForm.region2],
        // tradingDirection: data.ruleForm.radio1,
        acId: JSON.parse(sessionStorage.getItem("acid")),
        buyerCurrency: data.ruleForm.region,
        sellerCurrency: data.ruleForm.region2,
        purchasingAmount: data.ruleForm.money,
        exchangeRate: data.ruleForm.exchangeRate,
      }
      add(param).then((res) => {
        if (res.data.code == 200) {
          ElMessage.success(res.data.msg)
          accountAmount()
          // 重置表单
          resetForms(data)
        } else {
          ElMessage.error(res.data.msg)
        }
      })
    }

  })

}


// 查找用户的账号资金

export const accountAmount = (data) => {
  let param = {
    acId: JSON.parse(sessionStorage.getItem("acid")),
  }
  findUserAccountAmount(param).then((res) => {
    let newRes = res.data.data.userAccountAmount
    data.freeAmount = '0.00'
    data.amounts = '0.00'
    newRes.forEach(item => {
      let type = item.currencyType
      if (data.moneyTypeId1 == type) {
        data.freeAmount = item.freeAmount
      }
      if (data.moneyTypeId2 == type) {
        data.amounts = item.amounts
      }
    });
  })
}

// 重填按钮清空
export let resetForms = (data) => {
  data.ruleForm.region = ''
  data.ruleForm.region2 = ''
  data.ruleForm.radio1 = '1'
  data.ruleForm.money = ''
  data.amounts = '0.00'
  data.moneyTypeId1 = ''
  data.moneyTypeId2 = ''
  data.freeAmount = '0.00'
  data.ruleForm.exchangeRate = '1'
  data.options2.forEach(item => {
    item.disabled = false
  })
  data.options.forEach(item => {
    item.disabled = false
  })
}