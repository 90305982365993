export const storageData = {
        code:'nf_IC2109',
    codes:'10nf_IC2109',
    codeName:'豆一209',
        option:{
            xAxis: {
                data: ['2017-10-24', '2017-10-25', '2017-10-26', '2017-10-27','2017-10-28','2017-10-29','2017-10-30','2017-10-31']
            },
            yAxis: {},
            series: [{
                type: 'k',
                data: [
                    [20, 34, 10,38],
                    [40, 35, 30, 50],
                    [31, 38, 33, 44],
                    [38, 15, 5, 42],
                    [38, 15, 5, 42],
                    [38, 15, 5, 42],
                    [38, 15, 5, 10],
                ]
            }]
        }
}







//期货 结算单
export const fsettlementformData = {
    datetime:[],
    paginationConfig:{
        page:1,
        pageSize:20,
        total:100
    },
    tableData:[],
    colums:[
        {
            name: "合约代码",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "合约名称",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "状态",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "买卖",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "开平仓",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "成交价格",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "成交量",
            dataIndex: "order",
            width:'',
            align:'' 
        },
        {
            name: "交易费",
            dataIndex: "order",
            width:'',
            align:'' 
        },
    ],
    activekey:'1',
    navList:[
        {
            name:'当日成交',
            key: '1',
        },
        {
            name:'持仓纪录',
            key: '2',
        },
    ]
}