<template>
	<!-- 开方式基金-个人交易-成交详情 -->
	<div style="margin-left:20px;float: right;height: 40px;"> 
    <!-- 查询历史 &nbsp -->
		<el-tooltip effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
      <i style="color: #3B6EFB" class="el-icon-warning"></i>
    </el-tooltip>&nbsp
    <el-date-picker size="mini" v-model="data.historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期"
      @change="listCf()" value-format="YYYY-MM-DD" end-placeholder="结束日期">
    </el-date-picker>
	</div>
	
	<div style="margin-top: 20px;">
		<el-table :data="data.tableData" style="width: 100%" :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
			<el-table-column prop="id" label="交易单号" />
			<el-table-column prop="fundName" label="基金名称" />
			<el-table-column prop="amounts" label="交易金额" />
			<el-table-column prop="share" label="申购数量" />
			<el-table-column prop="type" label="交易类型" />
			<el-table-column prop="status" label="交易状态" />
			<el-table-column prop="createTime" label="交易时间" />
			<el-table-column label="操作">
				<template #default="scope">
					<!-- <div v-if="scope.row.status == '已成交'"><el-button size="small" disabled>撤单</el-button></div> -->
					<el-button size="small" :disabled="scope.row.status == '已撤单' || scope.row.status == '已成交' ? true : false"
						@click="chickCancelOrder(scope.row.id)">撤单</el-button>
				</template>

			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<div class="demo-pagination-block">
          <el-pagination :current-page="data.page" :page-size="data.pageSize" :page-sizes="[5, 10, 15, 20]"
            layout="total, sizes, prev, pager, next, jumper" :total="data.total" @size-change="sizeChange"
            @current-change="currentChange" />
        </div>
	</div>
</template>

<script setup>
import { reactive, toRefs, onMounted } from 'vue';
import { listTraded, cancelOrder } from '../../api.js';
import { ElMessage } from 'element-plus';

let data = reactive({
	historicalTime: '',
	tableData: [],
	page:1,
	pageSize:10,
	total:5,
})

const listCf = () => {
	let parm = {
		"acid": sessionStorage.getItem("acid"),
		"startTime": data.historicalTime[0],
		"endTime": data.historicalTime[1],
		"page": data.page,
		"pageSize": data.pageSize
	};
	listTraded(parm).then(res => {
		data.tableData = res.data.data.list;
		data.total = res.data.data.total
	})
}

const chickCancelOrder = (tradId) => {
	var formData = new FormData();
	formData.append("tradeId", tradId);
	formData.append("acid", sessionStorage.getItem("acid"));
	cancelOrder(formData).then(res => {
		if (res.data.code == '200') {
			ElMessage.success('撤单成功！')
			// ({ type: 'success', message: '撤单成功！' })
		} else {
			ElMessage.error(res.data.msg)
			// ({ type: 'error', message: res.data.msg })
		}
		listCf();
	})
}

const sizeChange = (val) =>{
	data.pageSize = val
	listCf();
}
const currentChange = (val) =>{ 
	data.page = val
	listCf();
}

onMounted(() => {
	listCf();
})
</script>

<style lang="less" scoped>
.demo-pagination-block {
  margin-top: 20px;
  float: right
}
</style>