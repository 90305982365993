import {
  forexOrders,
  cancelForexOrder
} from './ForeignExchangeCancellationAip'
import dayjs from "dayjs";
import {
  ElMessage
} from 'element-plus';
import {
  thousandthConversion
} from '../commonMethods'
// 撤单数据
export const queryOrder = (data) => {
  let params = {
    acId: JSON.parse(sessionStorage.getItem("acid")),
    orderStates: [1, 2],
    pageIndex: data.pageIndex,
    pageSize: data.pageSize,
  }
  forexOrders(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {
      data.total = newRes.data.total
      newRes.data.forexOrderVOList.forEach(item => {
        console.log('0000000', item);
        item.exchangeRates = thousandthConversion(item.exchangeRate)
        item.purchasingAmounts = thousandthConversion(item.purchasingAmount)
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
        if (item.orderState == 1) {
          item.orderState = "委托中"
        } else if (item.orderState == 2) {
          item.orderState = "已取消"
        } else if (item.orderState == 3) {
          item.orderState = "交易完成"
        }
      });
      data.forexOrderVOList = newRes.data.forexOrderVOList
    }
  })
}
// 查询历史 

export const queryCancellations = (data) => {
  let startTime = "";
  let endTime = "";
  if (data.historicalTime != null) {
    if (
      data.historicalTime[0] &&
      data.historicalTime[0] != null &&
      data.historicalTime[0] != undefined
    ) {
      startTime = data.historicalTime[0];
      endTime = data.historicalTime[1].substring(0, 11) + "23:59:59";
    }
  }

  let params = {
    acId: JSON.parse(sessionStorage.getItem('acid')),
    orderStates: [1, 2],
    tradingDirection: data.accountType,
    startTime: startTime,
    endTime: endTime
  }
  forexOrders(params).then((res) => {
    let newRes = res.data
    if (newRes.code == 200) {

      data.total = newRes.data.total
      newRes.data.forexOrderVOList.forEach(item => {
        item.exchangeRates = thousandthConversion(item.exchangeRate)
        item.purchasingAmounts = thousandthConversion(item.purchasingAmount)
        item.createTime = dayjs(item.createTime).format("YYYY-MM-DD HH:mm:ss")
        if (item.orderState == 1) {
          item.orderState = "委托中"
        } else if (item.orderState == 2) {
          item.orderState = "已取消"
        } else if (item.orderState == 3) {
          item.orderState = "交易完成"
        }
      });
      data.forexOrderVOList = newRes.data.forexOrderVOList
    }
  })
}


// 撤单 
export const cancelButtons = (data, row) => {
  let params = {
    id: row.id
  }
  // console.log('data.forexOrderVOList.orderState',data.forexOrderVOList);
  cancelForexOrder(params).then(res => {
    if (res.data.code == 200) {
      ElMessage.success(res.data.msg)
      queryOrder(data)
    } else {
      ElMessage.error(res.data.msg)
    }
  })
}