<template>
  <div class="container">
    <div class="queryBox">
      选择日期：
      <el-date-picker style="margin-right: 10px" v-model="datetime" format="YYYY-MM-DD" size="mini" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-button type="primary" @click="query" size="mini">查询</el-button>
    </div>
    <div class="commom">
      <div class="title">资金概况</div>
      <CommonTable :colums="colums" :tableData="tableData" :showPage="false" />
    </div>
    <div class="commom">
      <div class="title">出入金明细</div>
      <CommonTable :colums="colums" :tableData="tableData" :showPage="false" />
    </div>
    <div class="tabbox">
      <NavSonMenu :activeTableftkey="activekey" :menuData="navList" @menuChange="menuChange" />
      <CommonTable :colums="colums" :tableData="tableData" :showPage="false" />
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { fsettlementformData } from "../futuresData";
import CommonTable from "../Table";
import NavSonMenu from "@/components/NavSonMenu"
export default {
  name: "fsettlementform",
  components: {
    CommonTable,
    NavSonMenu
  },
  setup() {
    const data = reactive(fsettlementformData);

    const query = () => {};

    const handleChange = (key, val) => {
      data.paginationConfig[key] = val;
    };

    const menuChange = (tab) => {
      data.activekey = tab.key
    }

    const handleDate = (timeList) => {
      let _arr = [];
      timeList.forEach((item) => {
        _arr.push(dayjs(item).format("YYYY-MM-DD"));
      });
      return _arr;
    };

    return {
      query,
      handleChange,
      menuChange,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="less" scoped>
.queryBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.commom {
  margin-bottom: 30px;
  .title {
    margin-bottom: 20px;
  }
}
</style>
