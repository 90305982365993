<template>
  <div class="trade-personal">
    <navSonMenuNew :activeTableftkey="activeTableTopkey" :menuData="navList" @menuChange="menuChange($event, 'top')" />

    <div class="rightTable">
      <div class="leftNav">
      <NavSonMenu :activeTableftkey="activeTableftkey" :direction="false" :menuData="tradeList"
        @menuChange="menuChange($event, 'left')" />
    </div>
      <div class="dynamicContainer">
        <Component :is="componentName" :type="activeTableTopkey" :componentType="componentType"></Component>

      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, nextTick, onBeforeUnmount, onMounted } from "vue"
import { useRouter } from "vue-router"
import NavSonMenu from "@/components/NavSonMenu"
import navSonMenuNew from "@/components/NavSonMenu/navSonMenuNew"
import { initdata, LeftTabData, stockList } from "./data"

//复用组件
import market from "./components/Market"
import ForeignExchangeMarket from "./components/ForeignExchangeMarket"
import Founds from "./components/Funds/index.vue"
import Withwithdrawal from "./components/Withwithdrawal/index.vue"
import OperationDay from "./components/OperationDay/index.vue"
import HistoryTransaction from "./components/HistoryCapital/index.vue"
import OpenEndedFundsMarket from "./components/FundApplyForPurchasing"



//股票
import buy from "./components/Buy/individual"
import sale from "./components/Sale"
//期货
import futuresstorage from "./components/Storage"
import fsettlementform from "./components/FsettlementForm"
import ForwardMarket from "./components/ForwardMarket"

//债券
import bbuy from "./components/Bbuy"
import bsale from "./components/Bsale"
import { getAcidByclassID } from "./api.js"

//外汇
import ForeignExchangeTransactions from "./components/ForeignExchangeTransactions" //外汇交易
import ForeignExchangeCapitalFlow from "./components/ForeignExchangeCapitalFlow" //外汇资金流水
import ForeignExchangeAccountInformation from "./components/ForeignExchangeAccountInformation" //外汇账户信息
import ForeignExchangeTransactionDetails from "./components/ForeignExchangeTransactionDetails" //外汇成交详情
import ForeignExchangeCancellation from "./components/ForeignExchangeCancellation" //外汇撤单

// 开放式基金 
import FundApplyForPurchasing from "./components/FundApplyForPurchasing" // 开放式基金 申购
import FundRedeem from "./components/FundRedeem" // 开放式基金 赎回
import FundCapitalFlow from "./components/FundCapitalFlow" // 开放式基金 资金流水
import FundCapitalShares from "./components/FundCapitalShares" // 开放式基金 资金股份
import FundTransactionDetails from "./components/FundTransactionDetails" // 开放式基金 成交详情
import FundInvestor from "./components/FundInvestor/index4.vue" // 开放式基金 投资者问答

export default {
  name: 'indexStock',
  components: {
    NavSonMenu,
    navSonMenuNew,
    market,
    ForeignExchangeMarket,
    buy,
    sale,
    futuresstorage,
    fsettlementform,
    bbuy,
    bsale,
    OpenEndedFundsMarket,
    Founds,
    Withwithdrawal,
    OperationDay,
    HistoryTransaction,
    ForeignExchangeTransactions,
    ForeignExchangeCapitalFlow,
    ForeignExchangeAccountInformation,
    ForeignExchangeTransactionDetails,
    FundApplyForPurchasing,
    FundRedeem,
    FundCapitalFlow,
    FundCapitalShares,
    FundTransactionDetails,
    FundInvestor,
    ForeignExchangeCancellation,
    ForwardMarket
  },
  setup() {
    const data = reactive({
      componentName: 'market',//当前展示的vue组件
      activeTableftkey: '1', //当前左侧被选中的key
      activeTableTopkey: '1',//当前顶部tab栏被选中的key
      tradeList: stockList,// 动态切换的左侧tab数组
      componentType: '1',  //用来分辨动态组件复用组件左侧菜单选中的是什么组件
      navList: [
        {
          name: '股票',
          type: 'stock',
          key: '1',
          siwtchKey: 'stockList',
          pageName: 'stock',
          componentName: 'market'
        },
        {
          name: '债券',
          type: 'bonds',
          key: '2',
          siwtchKey: 'bondsList',
          pageName: 'bonds',
          componentName: 'market'
        },
        //   期货 table切换暂时不用 
        // {
        //   name: '期货',
        //   type: 'futures',
        //   key: '3',
        //   siwtchKey: 'futuresList',
        //   pageName: 'futures',
        //   componentName: 'ForwardMarket'
        // },

        //   外汇table切换暂时不用 
        {
          name: '外汇',
          type: 'ForeignExchange',
          key: '4',
          siwtchKey: 'ForeignExchangeList',
          pageName: 'ForeignExchange',
          componentName: 'ForeignExchangeMarket'
        },
        {
          name: '开放式基金',
          type: 'OpenEndedFunds',
          key: '5',
          siwtchKey: 'OpenEndedFundsList',
          pageName: 'OpenEndedFunds',
          componentName: 'OpenEndedFundsMarket',
        },
      ],

    })
    const router = useRouter()

    onMounted(() => {
      getAcid();
      nextTick(() => {
        const routerQuery = router.currentRoute.value.query;
        if (routerQuery.hasOwnProperty('componentName') && routerQuery.componentName !== '') {
          data.componentName = routerQuery.componentName
        }
        if (routerQuery.hasOwnProperty('activeTableftkey') && routerQuery.activeTableftkey !== '') {
          data.activeTableftkey = routerQuery.activeTableftkey
        }
        if (routerQuery.hasOwnProperty('activeTableTopkey') && routerQuery.activeTableTopkey !== '') {
          data.activeTableTopkey = routerQuery.activeTableTopkey
        }
        if (routerQuery.hasOwnProperty('pageName') && routerQuery.pageName !== '') {
          data.tradeList = LeftTabData[`${routerQuery.pageName}List`]
        }
        if (routerQuery.hasOwnProperty('componentType') && routerQuery.componentType !== '') {
          data.componentType = routerQuery.componentType
        }

      })
    })
    nextTick(() => {
      const routerQuery = router.currentRoute.value.query;
      if (routerQuery.hasOwnProperty('componentName') && routerQuery.componentName !== '') {
        data.componentName = routerQuery.componentName
      }
      if (routerQuery.hasOwnProperty('activeTableftkey') && routerQuery.activeTableftkey !== '') {
        data.activeTableftkey = routerQuery.activeTableftkey
      }
      if (routerQuery.hasOwnProperty('activeTableTopkey') && routerQuery.activeTableTopkey !== '') {
        data.activeTableTopkey = routerQuery.activeTableTopkey
      }
      if (routerQuery.hasOwnProperty('pageName') && routerQuery.pageName !== '') {
        data.tradeList = LeftTabData[`${routerQuery.pageName}List`]
      }
      if (routerQuery.hasOwnProperty('componentType') && routerQuery.componentType !== '') {
        data.componentType = routerQuery.componentType
      }
    })

    let getAcid = () => {
      var parame = {
        username: JSON.parse(sessionStorage.getItem("user")).username,
        acidType: 4
      };
      getAcidByclassID(parame).then(res => {
        sessionStorage.setItem('acid', res.data.data)
      })
    }


    onBeforeUnmount(() => {
      //离开组件初始化到默认组件
      data.componentName = 'market'
    })


    const menuChange = (navitem, Navtype) => {
      data.componentName = navitem.componentName
      data.componentType = navitem.componentType ? navitem.componentType : ''
      let routerParmas = {
        componentName: navitem.componentName,
        pageName: navitem.type,
        activeTableftkey: '',
        activeTableTopkey: '',
        componentType: data.componentType
      }
      if (Navtype === 'left') {
        data.activeTableftkey = navitem.key
      } else {
        data.activeTableTopkey = navitem.key
        data.activeTableftkey = '1'  //切换顶部tab栏初始化 如果是点击横排股票期货初始化左侧选中及生成对应新的侧边栏
        data.tradeList = LeftTabData[navitem.siwtchKey]
      }
      routerParmas.activeTableftkey = data.activeTableftkey
      routerParmas.activeTableTopkey = data.activeTableTopkey
      router.push({
        path: '/course/trade-personal/stock',
        query: routerParmas
      })

    }

    return {
      ...toRefs(data),
      menuChange
    }
  }
}
</script>

<style lang="less" scoped>
.trade-personal {
  display: block;
  width: 100%;

  .leftNav {
    width: 100%;
    background-color: white;
  }

  .rightTable {
    // flex:1;
    // flex-shrink: 0;
    width: calc(100% - 30px);

    .dynamicContainer {
      // height: 70vh;
      height: calc(100vh - 275px);
      overflow: auto;
    }
  }
}
</style>
