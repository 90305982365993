<template>
  <el-form ref="salebuy" :model="formline" id="commonform" size="mini" :rules="rules" :hide-required-asterisk="true">
    <div class="flexform">
      <!-- 债券交易 -->
      <el-form-item label="证券代码" prop="name">
        <CodeSearch @codeSelectChane="codeSelectChane" codeType="2" :multiple="false" :codeList="code"></CodeSearch>
      </el-form-item>
      <el-form-item label="证券名称" >
        <div style="width: 100%;"> 
          <span style=" font-size: 14px;">{{codeName}}</span>
        </div>
      </el-form-item>
      <el-form-item label="买入价格" prop="price">
        <el-input v-model="formline.price" style="width: 100%;"></el-input>
      </el-form-item>
      <!--      <el-form-item label="最大可买:" prop="name">-->
      <!--        <el-input v-model="formline.name"></el-input>-->
      <!--        <span class="unit">张</span>-->
      <!--      </el-form-item>-->
      <el-form-item label="买入数量" prop="num">
        <el-input v-model="formline.num" @blur="blurNumber" style="width: 100%;"></el-input>
        <!-- <span class="unit">张</span> -->
      </el-form-item>
      <el-form-item label="报价方式" prop="way">
        <el-radio-group v-model="formline.way">
          <el-radio :label="2" style="margin-left: 20px;">限价</el-radio>
          <el-radio :label="3">市价</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="可用资金">
        <div class="money"><span style="float: right;">{{ money }}</span></div>
      </el-form-item>
      <div style="margin-bottom: 20px;">提示：债券每张面值100元，每1手为10张</div>
      
    </div>
    <el-form-item>
      <el-button style="width: 95%; font-size: 14px" type="primary" @click="isSalebuy" :disabled="buyDisabled.buyDisabled">模拟买入</el-button>
      <div style="width: 95%;text-align: center; color: #75728C;">
        <!-- <span  @click="resetForm"  class="reset">重填</span> -->
        <el-button  @click="resetForm" style="width: 100%;margin-top: 10px; font-size: 14px"  class="reset">重填</el-button>
      </div>
    </el-form-item>
    <!-- <el-form-item class="space">
      <el-button type="primary" class="handlebtn" @click="isSalebuy" :disabled="buyDisabled.buyDisabled">买入下单</el-button>
      <el-button class="reset" @click="resetForm">重填</el-button>
    </el-form-item> -->
    
  </el-form>
</template>

<script>
import { onMounted, reactive, ref, toRefs, watch } from "vue";
import { dealCodeSearch } from "../../../course-manage/trade-manage/tradeMethods";
import CodeSearch from "@/components/CodeSearch"
import { findFunds, placeAnOrder } from "../../../course-manage/trade-manage/components/TradeDetails/detailsApi";
import { codeDeal, codeDealName, dealCodeSearchNum } from "../commonMethods";
import { ElMessage } from "element-plus";

export default {
  name: "bondbuyform",
  components: {
    CodeSearch
  },
  props: {
    price: {
      type: String
    }
  },
  setup(props, ctx) {
    const salebuy = ref();
    const buyDisabled = reactive({ buyDisabled: false });
    const formline = reactive({
      name: "",
      way: "",
      price: "",
      radio: "",
      num: "",
    });
    let data = reactive({
      code: 'sz123091',
      money: '',
      codeName: '长海转债'
    })
    const rules = reactive({
      way: [{ required: true, message: "请选择报价方式", trigger: "trigger" }],
      price: [{ required: true, message: "请输入买入价格", trigger:["blur","change"] }],
      num: [{ required: true, message: "请输入买入价格", trigger: ["blur","change"] }],
    });

    onMounted(() => {
      geyMoney()
    })

    watch(() => [formline.num, props.price], ([newNum, newPrice]) => {
      if (isNaN(newNum)) formline.num = ''
      if (newPrice) formline.price = parseFloat(newPrice)
      if (newPrice === 0) formline.price = ''
    })

    let blurNumber = (val) => {
      let num = parseInt(val.target.value)
      let minNum = dealCodeSearchNum(data.code.slice(2), '2')
      formline.num = num - num % minNum
    }

    let geyMoney = () => {
      findFunds({
        acid: JSON.parse(sessionStorage.getItem('acid')),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        exchange: 0
      }).then(res => {
        if (res.data.code === '200') {
          data.money = res.data.data.funds[0].freeAmount
        }
      })
    }

    const resetForm = () => {
      ctx.emit('editPrice', 0)
      salebuy.value.resetFields();
      // formline.price = ''
      formline.num = ''
    };

    const isSalebuy = async () => {
      salebuy.value.validate((valid) => {
        if (valid) {
          let totalPrice = formline.price * formline.num
          if (totalPrice > data.money) {
            ElMessage.warning('买入总价不能大于可用资金')
            return 
          }
          buyDisabled.buyDisabled = true;
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            tposition: 0,
            insCode: codeDeal(data.code.slice(2), '2'),
            direction: 0,
            orderPrice: parseFloat(formline.price),
            orderVol: parseFloat(formline.num),
            code: data.code
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              buyDisabled.buyDisabled = false;
              ElMessage.success(res.data.msg)
              resetForm()
              setTimeout(() => {
                geyMoney()
              }, 4000)
            } else {
              buyDisabled.buyDisabled = false;
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    };

    // 切换 搜索
    let codeSelectChane = (value) => {
      // data.codeList = value
      data.code = dealCodeSearch(value, { routeId: '2' })
      data.codeName = codeDealName(value, '2')
      ctx.emit('codeChange', data.code)
    }

    return {
      ...toRefs(data),
      buyDisabled,
      formline,
      salebuy,
      rules,
      resetForm,
      isSalebuy,
      codeSelectChane,
      blurNumber,
    };
  },
};
</script>

<style lang="less" scoped>
#commonform {
  .flexform {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      width: 100%;
      display: flex;
      padding-bottom: 10px;
      // &:deep(.el-form-item__label) {
      //   width: 100px;
      // }
      &:deep(.el-form-item__content) {
        flex: 1;
        position: relative;
        padding-right: 20px;
        .unit {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(10%, -50%);
        }
      }
    }
  }
  .space {
    &:deep(.el-form-item__content) {
      display: flex;
      justify-content: space-around;
    }
  }
  .hiddenlabel {
    &:deep(.el-form-item__label) {
      visibility: hidden;
    }
  }

  .handlebtn {
    background: #feae10;
    border-color: #feae10;
  }
  .reset {
    font-size: 14px;
    // color: #feae10;
    // border-color: #feae10;
    background:#F4F4FA ;
    cursor:pointer;
  }
  .tips {
    .money {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      > span {
        font-weight: bold;
      }
    }
    .desc {
      font-size: 12px;
      color: #a8a8a8;
    }
  }
}
</style>
