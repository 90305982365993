<template>
  <!-- 外汇个人交易撤单 -->
  <div style="margin:5px 0 20px 20px;height: 30px;">
    <!-- 币种类型： -->
    <div style="float: left;">
       <el-select v-model="data.accountType" clearable @change="queryCancellation" placeholder="请选择币种" style="width: 280px;margin-right: 5px;"
        size="mini">
        <el-option v-for="item in data.currencyData" :key="item.id" :label="item.label" :value="item.abbr" />
      </el-select>
      <el-tooltip  effect="dark" content="选择币种查询" placement="top">
        <i style="color: #3B6EFB" class="el-icon-warning"></i>
      </el-tooltip>
    </div>
   
    <!-- 查询历史 -->
    <div style="float: right;"> 
        <!-- 查询历史 &nbsp -->
      <el-tooltip effect="dark" content="选择日期查询历史(默认查询当日)   " placement="top">
        <i style="color: #faad14" class="el-icon-warning"></i>
      </el-tooltip>&nbsp
      <el-date-picker size="mini" v-model="data.historicalTime" type="daterange" range-separator="至"
        start-placeholder="开始日期" value-format="YYYY-MM-DD HH:mm:ss" end-placeholder="结束日期" @change="queryCancellation">
      </el-date-picker>
    </div>
    
  </div>
  <div style="margin-top:20px;margin-left: 20px;clear: both;">
    <el-table :data="data.forexOrderVOList" style="width: 100%"
      :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }">
      <el-table-column label="交易时间" prop="createTime" />
      <el-table-column label="买入币种" prop="buyerCurrency" />
      <el-table-column label="卖出币种" prop="sellerCurrency" />
      <el-table-column label="卖出金额" prop="purchasingAmounts" />
      <el-table-column label="交易汇率" prop="exchangeRates" />
      <el-table-column label="订单状态" prop="orderState" />
      <el-table-column prop="date" label="操作">
        <template #default="scope">
          <el-button :disabled="scope.row.orderState == '已取消' ? true : false" @click="cancelButton(scope.row)">撤单
          </el-button>
        </template>

      </el-table-column>
    </el-table>
  </div>
  <!-- 分页 -->
  <div class="demo-pagination-block">
    <el-pagination :current-page="data.pageIndex" :page-size="data.pageSize" :page-sizes="[5, 10, 15, 20,]"
      layout="total, sizes, prev, pager, next, jumper" :total="data.total" @size-change="sizeChange"
      @current-change="currentChange" />
  </div>
</template>
<script setup >

import { onMounted, reactive, ref, } from 'vue';
import { queryOrder, queryCancellations, cancelButtons } from './ForeignExchangeCancellationMethods'

onMounted(() => {
  queryOrder(data)
  data.currencyData = JSON.parse(localStorage.getItem('CurrencyOptions')) //获取币种
})
let data = reactive({
  pageIndex:1,
  pageSize:10,
  disabled: false,
  total:'',
  accountType: '',
  historicalTime: '',
  forexOrderVOList: [],
  currencyData: [],
})
let queryCancellation = () => {
  queryCancellations(data)
}
let cancelButton = (row) => {
  cancelButtons(data, row)
}
let currentChange = (val) =>{
  data.pageIndex = val
  queryOrder(data)
}
let sizeChange = (val) =>{
  data.pageSize = val
  queryOrder(data)
}


</script>
<style lang="less" scoped>
.demo-pagination-block {
  margin-top: 20px;
  float: right
}
</style>