<template>
  <!-- 外汇-交易-资金流水 -->
  <div style="margin:5px 0 20px 20px;height: 30px;">
    <!-- 币种类型： -->
    <div style="float: left;">
      <el-select v-model="account" clearable placeholder="请选择币种" style="width: 280px;margin-right: 5px;" size="mini" @change="transactionFunds">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr"/> 
      </el-select>
      <el-tooltip  effect="dark" content="选择币种查询" placement="top">
        <i style="color: #3B6EFB" class="el-icon-warning"></i>
      </el-tooltip>
    </div>
   
    <!-- 查询历史 -->
    <div style="float: right;"> 
        <!-- 查询历史 &nbsp -->
      <el-tooltip effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
        <i style="color: #faad14" class="el-icon-warning"></i>
      </el-tooltip>&nbsp
      <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期" value-format="YYYY-MM-DD HH:mm:ss"
        end-placeholder="结束日期"  @change="transactionFunds">
      </el-date-picker>
    </div>
    
  </div>
  <!-- <div style="margin-top:20px">币种类型：
    <el-from>
      <el-select v-model="account" clearable placeholder="请选择币种类型！" size="mini" @change="transactionFunds">
        <el-option v-for="item in currencyData" :key="item.id" :label="item.name" :value="item.abbr"/> 
      </el-select>

      <span style="margin-left:20px"> 查询历史 &nbsp
        <el-tooltip class="item" effect="dark" content="默认查询当日" placement="top">
          <i style="color: #faad14" class="el-icon-warning"></i>
        </el-tooltip>&nbsp
      </span>
      <el-date-picker size="mini" v-model="historicalTime" type="daterange" range-separator="至" start-placeholder="开始日期" value-format="YYYY-MM-DD HH:mm:ss"
        end-placeholder="结束日期"  @change="transactionFunds">
      </el-date-picker>
    </el-from>
  </div> -->
  <div style="margin-top:20px;margin-left: 20px;" class="boerdeNone">
    <el-table :data="forexOrderVOList" style="width: 100%" class="boerdeNone"
      :header-cell-style="{ background: '#F8F8F8', color: '#03032C' }"> 
      <el-table-column label="交易时间" prop="createTime" width="230" />
      <el-table-column label="币种类型" prop="currency" />
      <el-table-column label="空闲资金" prop="currencyAmounts" />
      <el-table-column label="交易金额" prop="forexAmount" />
      <el-table-column label="交易汇率" prop="exchangeRate" />
      <el-table-column label="剩余资金" prop="currencyBalances" />
    </el-table>
  </div>
   <!-- 分页 -->
   <div class="demo-pagination-block">
      <el-pagination :current-page="currentPage" :page-size="pageSize" :page-sizes="[5, 10, 15, 20]" 
         layout="total, sizes, prev, pager, next, jumper" :total="total"
        @size-change="sizeChange" @current-change="currentChange" />
    </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
import { entrustedOrders,findCurrency,transactionFundss } from './ForeignExchangeCapitalFlowMethods'
export default {
  setup() {
    onMounted(()=>{
      entrustedOrders(data)
      findCurrency(data)
    })
    let data = reactive({
      historicalTime: ['',''],
      account: '',
      currentPage: 1,//当前页面为1
      pageSize: 10,//每页业条数
      total: 1,//条目总数
      flowChart: '',
      orderState:'',
      forexOrderVOList: [],//所有订单
      currencyData:[],//货币
    })
    let sizeChange = (val) => {
      data.pageSize = val
      entrustedOrders(data)
    }
    let currentChange = (val) => {
      data.currentPage = val
      entrustedOrders(data)
    }
    let transactionFunds = () => {
      transactionFundss(data)
    }
    return {
      ...toRefs(data),
      entrustedOrders,
      sizeChange,
      currentChange,
      transactionFunds
    }
  }
}
</script>
<style lang="less" scoped >
.boerdeNone {
  border: none;
}
.demo-pagination-block{
  margin-top: 20px;
  float: right
}
</style>