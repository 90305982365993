<template>
  <el-form ref="salebuy" :model="formline" id="commonform" size="mini" :rules="rules" :hide-required-asterisk="true">
    <div style="margin-top: 5px;">
      <el-form-item label="证券代码">
        <!--        <el-input v-model="formline.name"></el-input>-->
        <div style="width: 100%;">
          <CodeSearch @codeSelectChane="codeSelectChane" codeType="1" :multiple="false" :codeList="code"></CodeSearch>
        </div>

      </el-form-item>
      <el-form-item label="证券名称">
        <span style="font-size: 14px;margin-left: 5px;">{{ codeName }}</span>

      </el-form-item>
      <el-form-item label="卖出价格" prop="price">
        <el-input v-model="formline.price" style="width:100%;"></el-input>
      </el-form-item>
      <el-form-item label="卖出数量" prop="num">
        <el-input v-model="formline.num" @blur="blurNumber" style="width:100%;"></el-input>
        <!-- <span class="unit">股</span> -->
      </el-form-item>
      <el-form-item label="报价方式" prop="way">
        <el-select v-model="formline.way" placeholder="请选择报价方式" style="width:100%;">
          <el-option :value="'定价'" label="定价"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="space">
        <el-button style="width:100%;"  type="primary" class="handlebtn" @click="isSalebuy">卖出下单</el-button>
        <el-button style="width:100%;margin-left: -1px;margin-top: 10px;" class="reset" @click="resetForm">重填</el-button>
      </el-form-item>
    </div>
    <!--    <div class="maxbuy">-->
    <!--      <el-form-item label="卖出数量:" prop="num">-->
    <!--        <el-input v-model="formline.num"  @blur="blurNumber" style="margin-right:10px;"></el-input>-->
    <!--        <el-button icon="el-icon-plus" type="primary" class="handlebtn" @click="formline.radio=1">全部</el-button>-->
    <!--        <span style="margin-left:10px;">股</span>-->
    <!--      </el-form-item>-->
    <!--      <el-form-item prop="radio" label="最大可卖:" class="hiddenlabel">-->
    <!--          <el-radio-group v-model="formline.radio">-->
    <!--          <el-radio :label="0.5">1/2</el-radio>-->
    <!--          <el-radio :label="0.33">1/3</el-radio>-->
    <!--          <el-radio :label="0.25">1/4</el-radio>-->
    <!--          <el-radio :label="0.2">1/5</el-radio>-->
    <!--        </el-radio-group>-->
    <!--      </el-form-item>-->
    <!--    </div>-->

  </el-form>
</template>

<script>
import { reactive, ref, toRefs, watch } from "vue";
import { dealCodeSearch } from "../../../course-manage/trade-manage/tradeMethods";
import CodeSearch from "@/components/CodeSearch"
import { placeAnOrder } from "../../../course-manage/trade-manage/components/TradeDetails/detailsApi";
import { ElMessage } from "element-plus";
import { codeDeal, codeDealName, dealCodeSearchNum } from "../commonMethods.js";

export default {
  name: "SaleForm",
  components: {
    CodeSearch
  },
  props: {
    price: {
      type: String
    },
    code: {}
  },
  setup(props, ctx) {
    const salebuy = ref()
    let data = reactive({
      code: 'sh600000',
      codes: '',
      codeName: '浦发银行'
    })
    const formline = reactive({
      name: "",
      price: '',
      way: '',
      radio: 1,
      num: '',
      codeList: ''
    });
    const rules = reactive({
      way: [{ required: true, message: '请选择报价方式', trigger: 'trigger' },],
      price: [{ required: true, message: '请输入卖出价格', trigger: ["blur", "change"] }],
      num: [{ required: true, message: '请输入卖出数量', trigger: 'blur' }],

    })
    watch(() => [formline.num, props.price], ([newNum, newPrice]) => {
      if (isNaN(newNum)) formline.num = ''
      if (newPrice) formline.price = parseFloat(newPrice)
    })

    const resetForm = () => {
      salebuy.value.resetFields()
    }

    const isSalebuy = async () => {
      salebuy.value.validate((valid) => {
        if (valid) {
          /*
       * tposition 开仓  0 ，平仓方向 1
       * direction 买 0 卖 1 方向
       * 股票,债券 买  tp 0 dir 0
       * 股，债 卖  tp 1 dir 1
       * 期货 买开 tp  0 dir 0
       *    买平  tp 1  dir 0
       *    卖开   tp 0 dir 1
        *    卖平 tp 1 dir 1
       * */
          let parame = {
            accountId: JSON.parse(sessionStorage.getItem('acid')),
            tposition: 1,
            insCode: codeDeal(data.code.slice(2), '1'),
            direction: 1,
            orderPrice: parseFloat(formline.price),
            orderVol: parseFloat(formline.num),
            code: data.codes.slice(2)
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              ElMessage.success(res.data.msg)
              resetForm()
            } else {
              ElMessage.error(res.data.msg)
            }
          })
        }
      })
    }

    let blurNumber = (val) => {
      let num = parseInt(val.target.value)
      let minNum = dealCodeSearchNum(data.code.slice(2), '1')
      formline.num = num - num % minNum
    }




    // 切换 搜索
    let codeSelectChane = (value) => {
      data.code = dealCodeSearch(value, { routeId: '1' })
      data.codes = dealCodeSearch(value, { routeId: '1' })
      data.codeName = codeDealName(value, '1')
      ctx.emit('codeChange', data.codes)
    }
    return {
      ...toRefs(data),
      formline,
      salebuy,
      rules,
      resetForm,
      isSalebuy,
      codeSelectChane,
      blurNumber,

    };
  },
};
</script>

<style lang="less" scoped>
#commonform {
  // width: 100%;
    .el-form-item {
      width: 100%;
      display: flex;
      padding-bottom: 18px !important;
      // &:deep(.el-form-item__label) {
      //   width: 100px;
      // }

      // &:deep(.el-form-item__content) {
      //   flex: 1;
      //   position: relative;
      //   padding-right: 20px;

      //   .unit {
      //     margin-left: 15px;
      //     //position: absolute;
      //     //right: 0;
      //     //top: 50%;
      //     //transform: translate(10%, -50%);
      //   }
      // }

  }

  // .space {
  //   text-align: center;
  //   //&:deep(.el-form-item__content){
  //   //  display: flex;
  //   //  justify-content: space-around;
  //   //}
  // }

  // .hiddenlabel {
  //   &:deep(.el-form-item__label) {
  //     visibility: hidden;
  //   }
  // }

  // .maxbuy {
  //   display: flex;
  //   flex-wrap: wrap;
  //   width: 100%;

  //   .el-form-item {
  //     display: flex;
  //     width: 100%;

  //     &:deep(.el-form-item__label) {
  //       width: 100px;
  //     }

  //     &:deep(.el-form-item__content) {
  //       display: flex;

  //       .el-radio-group {
  //         display: flex;
  //         align-items: center;
  //         margin-left: 10px;

  //         .el-radio__input.is-checked .el-radio__inner {
  //           color: #feae10;
  //           background: #feae10;
  //           border-color: #feae10;
  //         }

  //         .el-radio__input.is-checked+.el-radio__label {
  //           color: #feae10;
  //         }
  //       }
  //     }
  //   }
  // }

  .handlebtn {
    background: #feae10;
    border-color: #feae10;
  }

  .reset {
    // color: #feae10;
    background:#F4F4FA ;
    // border-color: #feae10;
  }
}</style>
