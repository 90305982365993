<template>
	<!-- 开放式基金申购页面  -->
  <div >
	<el-row>
	<el-col :span="9">
      <div class="left">
        <el-form :model="data" ref="dataRef" :rules="rules" :hide-required-asterisk="true" size="mini">
          <el-form-item label="证券代码" prop="fundNameSelect">
            <el-select v-model="data.fundNameSelect" style="width: 100%;" filterable remote reserve-keyword placeholder="基金名称"
              :remote-method="listFund" :loading="data.loading" size="mini" @change="getFundInfo">
              <el-option v-for="(item, index) in data.fundList" :key="item.id" :label="item.fundName" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-divider></el-divider>
          <!-- <el-form-item label="基金名称:" prop="fundName">{{ data.fundInfo.fundName }}</el-form-item> -->
          <el-form-item label="可用资金" prop="freeAmount" style="text-align:right">{{ data.fundInfo.freeAmount }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="基金总分额" prop="fundShare" style="text-align:right">{{ data.fundInfo.fundShare }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="交易费率" prop="rate" style="text-align:right">{{ data.fundInfo.rate == 1000 ? data.fundInfo.rate + '元' : data.fundInfo.rate
          }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <!-- <el-form-item label="基金净资产总值:" prop="netAssets">{{ data.fundInfo.netAssets }}</el-form-item> -->
          <el-form-item label="基金单位净资产" prop="unitNetAssets" style="text-align:right">{{ data.fundInfo.unitNetAssets }}</el-form-item>
          <el-divider class="text-divider"></el-divider>
          <el-form-item label="购买金额" prop="number">
            <el-input v-model="data.number" style="width: 100%;" type="number" size="mini" placeholder="请输入购买金额" clearable @input="getFee" />
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-button style="width: 100%;margin: 20px 0;" type="primary" class="handlebtn" @click="buy">申购</el-button>
            <!-- <el-button size="small" @click="buy">申购</el-button> -->
          </el-form-item>
      </el-form>
      </div>
	
	</el-col>
	<el-col :span="15">
      <div style="margin: 20px;height: 60vh;overflow: auto;flex:1;">
        <div style="margin-bottom: 10px"><i class="card-line"></i>行情数据</div>
        <span v-if="data.date.length != 0">
          <ECharts :heightCharts="heightCharts" :option="data.option"></ECharts>
        </span>
        <span v-else>
          <el-empty description="暂无数据" />
        </span>
      </div>
	</el-col>
	</el-row>
  </div>
	
</template>

<script setup>
import ECharts from '@/components/Echarts'
import { onMounted, reactive, toRefs, ref, defineComponent } from 'vue';
import { openFundTransactionInfo, listApprovaledFund, openFundPurchase, getNetWorthData } from '../../api.js';
import { ElMessage } from 'element-plus';
import { findFundTrendByAcid } from '@/views/course-manage/group-manage/groupApi.js'
const dataRef = ref(null)
let data = reactive({
	number: ref(''),
	loading: ref(false),
	fundList: ref([]),
	fundNameSelect: ref(''), //证券代码
	fundInfo: {
		"id": '',
		"acid": '',
		"fundName": "",
		"freeAmount": "",
		"fundShare": "",
		"rate": '',
		"netAssets": "",
		"unitNetAssets": ""
	},
	option: {},
	heightCharts: 300,
	date: [],
	value: [],
})
// onMounted(() => {
// 	marketQuotations()
// })
// 校验
const rules = reactive({
	fundNameSelect: { required: true, message: '请选择证券代码！', trigger: 'blur', },
	number: { required: true, message: '请输入购买金额！', trigger: 'blur', }
})
//下拉查询
const listFund = (fund) => {
	var formData = new FormData();
	formData.append("fundName", fund);
	listApprovaledFund(formData).then(res => {
		data.fundList = res.data.data;
	})
}
//基金详情
const getFundInfo = () => {
	var formData = new FormData();
	formData.append("acid", sessionStorage.getItem("acid"));
	formData.append("fundId", data.fundNameSelect);
	openFundTransactionInfo(formData).then(res => {
		data.fundInfo = res.data.data;
		marketQuotations();
	})
}
//申购
const buy = () => {
	dataRef.value.validate((valid) => {
		if (valid) {
			let parm = {
				"acid": sessionStorage.getItem("acid"),
				"fundId": data.fundInfo.id,
				"fee": data.fundInfo.rate,
				"amount": data.number,
				"userId": JSON.parse(sessionStorage.getItem('user')).userId,
				"unitNetAssets": data.fundInfo.unitNetAssets
			}
			openFundPurchase(parm).then(res => {
				if (res.data.code == '200') {
					ElMessage.success('申购成功！')
					data.fundNameSelect = ''
					data.number = ''
					data.fundInfo = {}
				} else {
					ElMessage.error(res.data.msg)
				}
			})
		}
	})
}

//获取费率
const getFee = () => {
	if (data.number < 1000000) {
		data.fundInfo.rate = 0.015;
	} else if (data.number >= 1000000 && data.number < 5000000) {
		data.fundInfo.rate = 0.012;
	} else if (data.number >= 5000000) {
		data.fundInfo.rate = 1000;
	}
}
// 行情走势图
const marketQuotations = () => {


	var formData = new FormData();
	formData.append("fundId", data.fundInfo.id);
	getNetWorthData(formData).then((res) => {
		if (res.data.code == 200) {
			data.date = res.data.data.date;
			data.value = res.data.data.value;
			data.option = {
				tooltip: {
					trigger: "axis",
				},
				xAxis: {
					type: "category",
					data: data.date,
				},
				yAxis: {
					type: "value",
				},
				grid:{
            top:'20%',//距上边距
            left:'10%',//距离左边距
            right:'0%',//距离右边距
            bottom:'10%',//距离下边距
            containLabel:true
    },
				series: [{
					data: data.value,
					type: "line",
					smooth: true,
					lineStyle: {
						color: "#FEAE10",
					},
					itemStyle: {
						color: "#FEAE10",
					},
				},],
			};
		}
	})
}
</script>

<style lang="less" scoped>
.text-divider.el-divider--horizontal{
	margin:8px 0px!important;
}
.ruleForm {
	.ruleForm-item-right {
		width: 50%;
		max-width: 300px;
		min-width: 120px;
	}
}

.el-form-item {
	margin-bottom: 0px;
}

.el-form-item__label {
	margin-left: 50px;
	text-align: justify;
}
.left{
//   width: 35%;
  margin: 20px;
  padding:20px 13%;
  background: #F4F4FA;
  box-sizing: border-box;
}
/deep/ .el-divider--horizontal{
  margin: 18px 0px !important;
  margin-top: 18px  !important;
  padding: 0 !important;
//   margin-bottom:2px !important;
 
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;

  background: #3B6EFB;
}
</style>
