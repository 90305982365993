//股票卖出
export const saleData = {
        code:'sh600000',
        colums:[
            {
                name: "序号",
                dataIndex: "order",
                width:'180px',
                 align:''
            },
            {
                name: "股票代码",
                dataIndex: "",
                width:'',
                 align:''
            },
            {
                name: "股票简称",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "现价",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "涨幅（%）",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "最高",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "最低",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "开盘价",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "昨收",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "成交量",
                dataIndex: "",
                width:'',
                align:''
            },
            {
                name: "成交额",
                dataIndex: "",
                width:'',
                align:''
            },
        ],
        paginationConfig:{
            page:1,
            pageSize:20,
            total:100
        },
        tableData:[]
}

//股票买入
export const buyData = {
    code:'sh600000',
    codeList: 'sh600000',
    codeName:'浦发银行',
    option:{
        xAxis: {
            data: ['2017-10-24', '2017-10-25', '2017-10-26', '2017-10-27','2017-10-28','2017-10-29','2017-10-30','2017-10-31']
        },
        yAxis: {},
        series: [{
            type: 'k',
            data: [
                [20, 34, 10,38],
                [40, 35, 30, 50],
                [31, 38, 33, 44],
                [38, 15, 5, 42],
                [38, 15, 5, 42],
                [38, 15, 5, 42],
                [38, 15, 5, 10],
            ]
        }]
    }
}









